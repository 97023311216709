<!-- SubNavCard.vue -->
<template>
  <div class="">
    <div class="row d-table overflow-x-auto s-layout__index_header">
      <div class="btn-group w-auto " role="group">
        <button
          v-for="(button, index) in buttons"
          :key="index"
          type="button"
          @click="$router.push({ name: button.route })"
          class="btn w-auto"
          :class="{'active ': $route.name === button.route}"
        >
          <i :class="button.icon"></i>
          {{ button.label }}
        </button>
      </div> 
    </div>
    <div class="mt-3">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttons: {
      type: Array,
      required: true
    }
  }
}
</script>

