<!-- SalesIndex.vue -->
<template>
  <SubNavCardVue :buttons="buttons"/>
</template> 

<script>
import SubNavCardVue from '../../components/cards/SubNavCard.vue'

export default {
  components: { SubNavCardVue },
  data() {
    return { 
      buttons: [
        {
          label: this.$t("sale1"),
          icon: 'bi bi-clipboard-check-fill',
          route: 'sales-index'
        }, 
        {
          label: this.$t("sale2"),
          icon: 'bi bi-chat-left-dots-fill',
          route: 'sales-tovalidate-index'
        },
        {
          label: this.$t("sale3"),
          icon: 'bi bi-sticky-fill',
          route: 'sales-noticed-index'
        },
        {
          label: this.$t("sale4"),
          icon: 'bi bi-file-earmark-bar-graph-fill',
          route: 'sales-customer-report'
        },
        {
          label: 'Rapport d\'archives',
          icon: 'bi bi-archive-fill',
          route: 'sales-customer-report-archive'
        },
        {
          label: this.$t("sale5"),
          icon: 'bi bi-file-earmark-bar-graph',
          route: 'sales-statistics-report'
        },
        {
          label: this.$t("sale6"),
          icon: 'bi bi-bag-plus-fill',
          route: 'sales-create'
        }
      ]
    }
  }
}
</script>